import React from "react";

import OGP from "@/components/OGP";
import { Grid } from "@mui/material";

const NotFound = () => {
  return (
    <>
      <OGP isRoot={true} title={"404"} cardType={"summary_large_image"} />

      <Grid
        container
        style={{ height: "90vh" }}
        className="items-center text-center"
      >
        <Grid item md={12} className="errorpage">
          <h1>Error 404</h1>

          <div>お探しのページは見つかりませんでした。</div>
        </Grid>
      </Grid>
    </>
  );
};

export default NotFound;
